import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

export const FluidImage = ({ data }) => {
  return (
    <Image>
      <Img fluid={data} />
    </Image>
  )
}

const Image = styled.div`
  position: relative;
  max-width: 750px;
  margin-bottom: 70px;

  @media only screen and (min-width: 1250px) {
    left: 50%;
    margin-left: -375px;
  }
`
