import React from 'react'
import styled from 'styled-components'
import data from '../provider/image'
import Img from 'gatsby-image'
import { OpenEffect } from '../components/openEffect'

export const AboutPage = () => {
  return (
    <OpenEffect>
      <div style={{ marginLeft: '10%' }}>
        <ImageDiv>
          <Img fluid={data().profileImage.childImageSharp.fluid} />
        </ImageDiv>
        <Profile>
          <div>
            Born and raised in Kyoto, Japan and currently based in Berlin.
            <br />
            Shooting people and cities in films, since the age of 19.
            <br />
            <br />
            All my pictures are unset and unposed.
            <br />
            <br />
            <span>Contact: kame0008@gmail.com</span>
          </div>
        </Profile>
        <SocialIcons>
          <Icon href={'https://www.flickr.com/photos/liewec/'} target={'_blank'} rel={'noreferrer'}>
            <Img fluid={data().flickr.childImageSharp.fluid} />
          </Icon>
          <Icon
            href={'https://www.instagram.com/tk_streetphotography/'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Img fluid={data().instagram.childImageSharp.fluid} />
          </Icon>
          <Icon
            href={'https://www.facebook.com/toshiki.kamei.3/'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Img fluid={data().facebook.childImageSharp.fluid} />
          </Icon>
        </SocialIcons>
      </div>
    </OpenEffect>
  )
}

const ImageDiv = styled.div`
  width: 230px;
`

const Profile = styled.div`
  margin-top: 40px;
  font-size: 0.9rem;
`

const SocialIcons = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;

  > a {
    margin-right: 20px;
  }
`

const Icon = styled.a`
  width: 35px;
  cursor: pointer;
`
