import React from 'react'

import SEO from '../components/seo'
import { ScrollFadeIn } from '../components/scrollFadeIn'
import { OpenEffect } from '../components/openEffect'
import { FluidImage } from '../components/image'
import data from '../provider/image'

export const PhotoPage = () => {
  const renderImages = () => {
    const images = []

    for (let i = 1; i < 32; i++) {
      images.push(
        <ScrollFadeIn key={i}>
          <FluidImage data={data()[`photoImage${i}`].childImageSharp.fluid} />
        </ScrollFadeIn>
      )
    }

    return images
  }

  return (
    <OpenEffect>
      <SEO title="photographs" />
      <div>{renderImages()}</div>
    </OpenEffect>
  )
}
