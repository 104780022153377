import React from 'react'
import styled from 'styled-components'

export const Page = ({ onClick, children }) => {
  return <PageDiv onClick={onClick}>{children}</PageDiv>
}

const PageDiv = styled.div`
  margin: 10vh 5vw 0 5vw;
`
