import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { CloseEffect } from './closeEffect'
import Button from '../images/btn.png'
import effectTime from '../provider/effectTime'

export const Panel = ({ panel, togglePanel, pathname }) => {
  const [closing, setClosing] = useState(false)

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const navigateTo = async (path) => {
    if (pathname !== '/') togglePanel()
    setClosing(true)
    await delay(effectTime)
    navigate(path, { state: { inside: true } })
    setClosing(false)
  }

  const jumpToBlog = async () => {
    if (typeof window !== 'undefined') {
      if (pathname !== '/') togglePanel()
      setClosing(true)
      // await delay(400)
      window.location.href = 'https://toshikikamei.com/blog'
    }
  }

  const menuList = () => (
    <>
      <div
        tabIndex="0"
        role="button"
        onClick={pathname === '/photographs' ? null : () => navigateTo('photographs')}
        onKeyPress={pathname === '/photographs' ? null : () => navigateTo('photographs')}
      >
        Photographs
      </div>
      <div
        tabIndex="0"
        role="button"
        onClick={pathname === '/sets' ? null : () => navigateTo('sets')}
        onKeyPress={pathname === '/sets' ? null : () => navigateTo('sets')}
      >
        Sets
      </div>
      <div tabIndex="0" role="button" onClick={jumpToBlog} onKeyPress={jumpToBlog}>
        Blog
      </div>
      <div
        tabIndex="0"
        role="button"
        onClick={pathname === '/about' ? null : () => navigateTo('about')}
        onKeyPress={pathname === '/about' ? null : () => navigateTo('about')}
      >
        About
      </div>
    </>
  )
  return (
    <>
      {closing ? <CloseEffect /> : null}
      <Wrapper pathname={pathname}>
        <PanelDiv>
          <Title
            role={'button'}
            pathname={pathname}
            onClick={pathname === '/' ? null : () => navigateTo('/')}
          >
            TOSHIKI KAMEI
          </Title>
          <Nav>{menuList()}</Nav>
        </PanelDiv>
      </Wrapper>
      <SmallPanel panel={panel} pathname={pathname}>
        <ToggleButton onClick={togglePanel} onDragStart={togglePanel} draggable>
          <img alt="menu" style={{ marginTop: '5px' }} src={Button} />
        </ToggleButton>
        <h1
          role={'button'} // eslint-disable-line
          onClick={pathname === '/' ? null : () => navigateTo('/')}
          onKeyDown={pathname === '/' ? null : () => navigateTo('/')}
        >
          TOSHIKI KAMEI
        </h1>
        <Nav center={true}>{menuList()}</Nav>
      </SmallPanel>
    </>
  )
}

const SmallPanel = styled.div`
  position: fixed;
  bottom: 0;
  display: grid;
  justify-items: center;
  width: 100vw;
  padding-bottom: 40px;
  background-color: #222;
  color: white;
  z-index: 900;

  transform: ${({ panel }) => (panel ? 'translateY(0)' : 'translateY(89%)')};
  transition: transform 500ms ease;

  ${({ pathname }) =>
    pathname === '/'
      ? `
      display: none;
      visibility: hidden;
      `
      : ''};

  @media only screen and (min-width: 701px) {
    display: none;
    visibility: hidden;
  }

  h1 {
    font-weight: 300;
    margin-bottom: 50px;
    cursor: pointer;
  }
`

const ToggleButton = styled.div`
  width: 100px;
  height: 40px;
  border-radius: 20px;
  margin-top: -15px;
  background-color: #222;
  text-align: center;
  cursor: pointer;
`

const Wrapper = styled.div`
  color: ${({ pathname }) => {
    return pathname === '/' ? 'white' : '#333'
  }};

  ${({ pathname }) =>
    pathname === '/'
      ? ''
      : `
        @media only screen and (max-width: 700px) {
          display: none;
          visibility: hidden;
        }
      `};
`

const PanelDiv = styled.div`
  z-index: 900;
  position: sticky;
  top: 10vh;
`

const Title = styled.h1`
  margin: 10vh 0 40vh 0;
  width: max-content;
  font-size: 2.2rem;
  font-weight: 300;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    font-size: 1.7rem;
  }

  @media only screen and (max-width: 700px) {
    font-size: 2.2rem;
  }

  ${({ pathname }) => (pathname === '/' ? `font-size: 2.2rem !important` : '')}
`

const Nav = styled.nav`
  display: grid;
  width: min-content;
  line-height: 2.2rem;
  font-weight: 300;
  font-size: 1.2rem;

  ${({ center }) => (center ? `justify-items: center` : '')};

  div {
    width: min-content;
    cursor: pointer;
    letter-spacing: 0.04rem;

    :focus {
      outline: 0;
    }
  }
`
