import React from 'react'
import styled, { keyframes } from 'styled-components'
import ms from '../provider/effectTime'

export const OpenEffect = (props) => {
  return (
    <>
      <ParentDiv ms={ms}>
        <Div ms={ms} slide={slideIn1} />
        <Div ms={ms} slide={slideIn2} />
        <Div ms={ms} slide={slideIn3} />
        <Div ms={ms} slide={slideIn4} />
        <Div ms={ms} slide={slideIn5} />
      </ParentDiv>
      {props.children}
    </>
  )
}

const slideIn1 = keyframes`
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
`

const slideIn2 = keyframes`
  0% {
    height: 100vh;
  }
  20% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
`

const slideIn3 = keyframes`
  0% {
    height: 100vh;
  }
  40% {
    height: 100vh;
  } 
  100% {
    height: 0;
  }
`

const slideIn4 = keyframes`
  0% {
    height: 100vh;
  }
  60% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
`

const slideIn5 = keyframes`
  0% {
    height: 100vh;
  }
  80% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
`

const dissapear = keyframes`
  0% {
    width: 100vw;
    height: 100vh;
  }
  99% {
    width: 100vw;
    height: 100vh;
  }
  100% {
    width: 0;
    height: 0;
  }
`

const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: start;
  width: 0vw;
  height: 0vh;
  z-index: 999;

  animation: ${dissapear} ${({ ms }) => ms + 10}ms;
`

const Div = styled.div`
  z-index: 999;
  height: 0vh;
  background: #111;
  animation: ${({ slide }) => slide} ${({ ms }) => ms}ms ease-in;
`
