import React from 'react'
import Helmet from 'react-helmet'

export const BlogPage = (props) => {
  const article = props['*']
  return (
    <Helmet>
      <meta httpEquiv={'refresh'} content={`0; URL='http://blog.toshikikamei.com/${article}'`} />
    </Helmet>
  )
}
