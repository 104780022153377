import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Router } from '@reach/router'

import Layout from '../components/layout/layout'
import { Wrapper } from '../components/layout/wrapper'
import { Page } from '../components/layout/page'
import { Panel } from '../components/panel'
import { TopPage } from '../containers/topPage'
import { PhotoPage } from '../containers/photoPage'
import { SetPage } from '../containers/setPage'
import { AboutPage } from '../containers/aboutPage'
import { BlogPage } from '../containers/blogPage'
import { BlackFrame } from '../components/blackFrame'

const IndexPage = ({ location: { pathname } }) => {
  const [panel, setPanel] = useState(false)

  const Default = () => {
    if (typeof window !== 'undefined') navigate('/')
    return null
  }

  return (
    <Layout>
      <Wrapper pathname={pathname}>
        {pathname === '/' ? null : <BlackFrame />}
        <Panel pathname={pathname} panel={panel} togglePanel={() => setPanel(!panel)} />
        <Page onClick={panel ? () => setPanel(!panel) : null}>
          <Router basepath="/">
            <PhotoPage path="/photographs" />
            <SetPage path="/sets" />
            <AboutPage path="/about" />
            <BlogPage path="/blog/*" />
            <TopPage path="/" />
            <Default default />
          </Router>
        </Page>
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
