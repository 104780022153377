import React, { useState } from 'react'
import styled from 'styled-components'
import { FluidImage } from './image'
import { PhotoSet } from './photoSet'

export const Jacket = ({ data, title, fileName, cap1, cap2, cap3, num }) => {
  const [contentIsVisible, setContents] = useState(false)
  const [closing, setClosing] = useState(false)

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  return (
    <>
      <JacketDiv
        onClick={() => {
          setContents(true)
          setClosing(false)
        }}
      >
        <FluidImage data={data} />
        <Caption>
          <h3>{title}</h3>
          <div>
            {cap1}
            <br />
            {cap2}
            <br />
            {cap3}
          </div>
        </Caption>
      </JacketDiv>
      <PhotoSet
        title={title}
        num={num}
        visible={contentIsVisible}
        fileName={fileName}
        closing={closing}
        onClick={async () => {
          setClosing(true)
          await delay(600)
          setContents(false)
        }}
      />
    </>
  )
}

const JacketDiv = styled.div`
  position: relative;
  margin-bottom: 180px;
  cursor: pointer;
`

const Caption = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0;
  height: 120px;
  margin-bottom: -120px;
  text-align: right;

  @media only screen and (min-width: 1250px) {
    right: 50%;
    margin-right: -370px;
  }

  h3 {
    margin: 10px 0 0 0;
    font-size: 1.3rem;
    font-weight: 400;
  }

  > div {
    font-weight: 300;
  }
`
