import React from 'react'
import styled from 'styled-components'

export const Wrapper = (props) => {
  return <WrapperDiv pathname={props.pathname}>{props.children}</WrapperDiv>
}

const WrapperDiv = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  margin-left: 10%;

  ${({ pathname }) =>
    pathname === '/'
      ? ''
      : `
    @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
  `}
`
