import { useStaticQuery, graphql } from 'gatsby'

const Image = () => {
  const data = useStaticQuery(graphql`
    query Profile {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bgImage: file(relativePath: { eq: "bg2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      profileImage: file(relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      instagram: file(relativePath: { eq: "social/i.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      facebook: file(relativePath: { eq: "social/f.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      flickr: file(relativePath: { eq: "social/fl.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage1: file(relativePath: { eq: "photo/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage2: file(relativePath: { eq: "photo/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage3: file(relativePath: { eq: "photo/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage4: file(relativePath: { eq: "photo/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage5: file(relativePath: { eq: "photo/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage6: file(relativePath: { eq: "photo/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage7: file(relativePath: { eq: "photo/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage8: file(relativePath: { eq: "photo/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage9: file(relativePath: { eq: "photo/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage10: file(relativePath: { eq: "photo/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage11: file(relativePath: { eq: "photo/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage12: file(relativePath: { eq: "photo/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage13: file(relativePath: { eq: "photo/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage14: file(relativePath: { eq: "photo/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage15: file(relativePath: { eq: "photo/15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage16: file(relativePath: { eq: "photo/16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage17: file(relativePath: { eq: "photo/17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage18: file(relativePath: { eq: "photo/18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage19: file(relativePath: { eq: "photo/19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage20: file(relativePath: { eq: "photo/20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage21: file(relativePath: { eq: "photo/21.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage22: file(relativePath: { eq: "photo/22.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage23: file(relativePath: { eq: "photo/23.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage24: file(relativePath: { eq: "photo/24.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage25: file(relativePath: { eq: "photo/25.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage26: file(relativePath: { eq: "photo/26.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage27: file(relativePath: { eq: "photo/27.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage28: file(relativePath: { eq: "photo/28.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage29: file(relativePath: { eq: "photo/29.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage30: file(relativePath: { eq: "photo/30.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photoImage31: file(relativePath: { eq: "photo/31.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding: file(relativePath: { eq: "sets/jackets/after_the_ending.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_1: file(relativePath: { eq: "sets/after_the_ending/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_2: file(relativePath: { eq: "sets/after_the_ending/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_3: file(relativePath: { eq: "sets/after_the_ending/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_4: file(relativePath: { eq: "sets/after_the_ending/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_5: file(relativePath: { eq: "sets/after_the_ending/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_6: file(relativePath: { eq: "sets/after_the_ending/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_7: file(relativePath: { eq: "sets/after_the_ending/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_8: file(relativePath: { eq: "sets/after_the_ending/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_9: file(relativePath: { eq: "sets/after_the_ending/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_10: file(relativePath: { eq: "sets/after_the_ending/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_11: file(relativePath: { eq: "sets/after_the_ending/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_12: file(relativePath: { eq: "sets/after_the_ending/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_13: file(relativePath: { eq: "sets/after_the_ending/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_14: file(relativePath: { eq: "sets/after_the_ending/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_15: file(relativePath: { eq: "sets/after_the_ending/15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_16: file(relativePath: { eq: "sets/after_the_ending/16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_17: file(relativePath: { eq: "sets/after_the_ending/17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_18: file(relativePath: { eq: "sets/after_the_ending/18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_19: file(relativePath: { eq: "sets/after_the_ending/19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_20: file(relativePath: { eq: "sets/after_the_ending/20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_21: file(relativePath: { eq: "sets/after_the_ending/21.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_22: file(relativePath: { eq: "sets/after_the_ending/22.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_23: file(relativePath: { eq: "sets/after_the_ending/23.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_24: file(relativePath: { eq: "sets/after_the_ending/24.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_25: file(relativePath: { eq: "sets/after_the_ending/25.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_26: file(relativePath: { eq: "sets/after_the_ending/26.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_27: file(relativePath: { eq: "sets/after_the_ending/27.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_28: file(relativePath: { eq: "sets/after_the_ending/28.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_29: file(relativePath: { eq: "sets/after_the_ending/29.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_30: file(relativePath: { eq: "sets/after_the_ending/30.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_31: file(relativePath: { eq: "sets/after_the_ending/31.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_32: file(relativePath: { eq: "sets/after_the_ending/32.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_33: file(relativePath: { eq: "sets/after_the_ending/33.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_34: file(relativePath: { eq: "sets/after_the_ending/34.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_35: file(relativePath: { eq: "sets/after_the_ending/35.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_36: file(relativePath: { eq: "sets/after_the_ending/36.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_37: file(relativePath: { eq: "sets/after_the_ending/37.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_38: file(relativePath: { eq: "sets/after_the_ending/38.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_39: file(relativePath: { eq: "sets/after_the_ending/39.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_40: file(relativePath: { eq: "sets/after_the_ending/40.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_41: file(relativePath: { eq: "sets/after_the_ending/41.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_42: file(relativePath: { eq: "sets/after_the_ending/42.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_43: file(relativePath: { eq: "sets/after_the_ending/43.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_44: file(relativePath: { eq: "sets/after_the_ending/44.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_45: file(relativePath: { eq: "sets/after_the_ending/45.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_46: file(relativePath: { eq: "sets/after_the_ending/46.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_47: file(relativePath: { eq: "sets/after_the_ending/47.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_48: file(relativePath: { eq: "sets/after_the_ending/48.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_49: file(relativePath: { eq: "sets/after_the_ending/49.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_50: file(relativePath: { eq: "sets/after_the_ending/50.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_51: file(relativePath: { eq: "sets/after_the_ending/51.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      afterTheEnding_52: file(relativePath: { eq: "sets/after_the_ending/52.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere: file(relativePath: { eq: "sets/jackets/i_wont_belong_here.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_1: file(relativePath: { eq: "sets/i_wont_belong_here/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_2: file(relativePath: { eq: "sets/i_wont_belong_here/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_3: file(relativePath: { eq: "sets/i_wont_belong_here/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_4: file(relativePath: { eq: "sets/i_wont_belong_here/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_5: file(relativePath: { eq: "sets/i_wont_belong_here/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_6: file(relativePath: { eq: "sets/i_wont_belong_here/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_7: file(relativePath: { eq: "sets/i_wont_belong_here/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_8: file(relativePath: { eq: "sets/i_wont_belong_here/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_9: file(relativePath: { eq: "sets/i_wont_belong_here/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_10: file(relativePath: { eq: "sets/i_wont_belong_here/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iWontBelongHere_11: file(relativePath: { eq: "sets/i_wont_belong_here/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto: file(relativePath: { eq: "sets/jackets/a_day_in_snow_kyoto.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_1: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_2: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_3: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_4: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_5: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_6: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_7: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_8: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_9: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_10: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_11: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_12: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_13: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_14: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_15: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_16: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_17: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_18: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_19: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_20: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_21: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/21.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_22: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/22.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_23: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/23.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_24: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/24.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_25: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/25.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_26: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/26.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aDayInSnowKyoto_27: file(relativePath: { eq: "sets/a_day_in_snow_kyoto/27.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}

export default Image
