import React from 'react'
import SEO from '../components/seo'
import { OpenEffect } from '../components/openEffect'
import { Jacket } from '../components/photoSetJacket'
import data from '../provider/image'

export const SetPage = () => {
  return (
    <OpenEffect>
      <SEO title={'Sets'} />
      <div>
        <Jacket
          data={data().afterTheEnding.childImageSharp.fluid}
          fileName={'afterTheEnding'}
          num={52}
          title={'After The Ending'}
          cap1={'There is the end and after.'}
          cap2={'Where are you then?'}
          cap3={'Where are we going?'}
        />

        <Jacket
          data={data().iWontBelongHere.childImageSharp.fluid}
          fileName={'iWontBelongHere'}
          num={11}
          title={"I Won't Belong Here"}
          cap1={'I feel like taking pictures is like loving.'}
          cap2={'Loving through seeing.'}
          cap3={'I always wanna say, "Don\'t worry, I saw you."'}
        />

        <Jacket
          data={data().aDayInSnowKyoto.childImageSharp.fluid}
          fileName={'aDayInSnowKyoto'}
          num={27}
          title={'A Day in Snow, Kyoto'}
          cap1={'It merely snows in Kyoto.'}
          cap2={'A day in record-breaking snow.'}
          cap3={'There was fuss and silence.'}
        />
      </div>
    </OpenEffect>
  )
}
