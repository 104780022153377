import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Wrapper } from './layout/wrapper'
import { Page } from './layout/page'
import { ScrollFadeIn } from './scrollFadeIn'
import { FluidImage } from './image'
import data from '../provider/image'

export const PhotoSet = ({ visible, title, fileName, onClick, closing, num }) => {
  const render = () => {
    const images = []

    for (let i = 1; i <= num; i++) {
      images.push(
        <ScrollFadeIn key={i}>
          <FluidImage data={data()[`${fileName}_${i}`].childImageSharp.fluid} />
        </ScrollFadeIn>
      )
    }

    return images
  }

  return visible ? (
    <Fixed closing={closing}>
      <Wrapper>
        <Caption>
          <h3>{title}</h3>
          <div role={'button'} tabIndex={0} onClick={onClick} onKeyDown={onClick}>
            &gt;&gt;
          </div>
        </Caption>
        <Page>
          <SmartPhoneNav closing={closing}>
            <div />
            <div>{title}</div>
            <div role={'button'} tabIndex={0} onClick={onClick} onKeyDown={onClick}>
              &gt;&gt;
            </div>
          </SmartPhoneNav>
          <PhotoList>{render()}</PhotoList>
        </Page>
      </Wrapper>
    </Fixed>
  ) : null
}

const fadeIn = keyframes`
  0% {
    transform: translateX(100vw);
  }
  
  100% {
    transform: translateX(0);
  }
`

const SmartPhoneNav = styled.div`
  position: fixed;
  top: 0;
  left: ${({ closing }) => (closing ? '100vw' : '0')};
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  justify-items: center;
  align-items: center;
  width: 100vw;
  height: 70px;
  background-color: white;
  box-shadow: 0 1px 8px 1px rgba(100, 100, 100, 0.2);
  font-size: 1rem;
  z-index: 901;

  transition: right 0.6s ease;

  > div:last-child {
    font-size: 1.3rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 701px) {
    display: none;
  }
`

const Fixed = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0;
  right: ${({ closing }) => (closing ? '-100vw' : '0')};
  width: 100vw;
  height: 100vh;

  background-color: white;
  z-index: 900;
  cursor: default;

  transition: right 0.6s ease;
  animation: ${fadeIn} ease 1s;
`

const PhotoList = styled.div`
  overflow: scroll;

  @media only screen and (max-width: 700px) {
    margin-top: 40px;
  }
`

const Caption = styled.div`
  @media only screen and (max-width: 700px) {
    display: none;
  }
  h3 {
    position: sticky;
    top: 10vh;
    margin: 10vh 0 0 0;
    width: max-content;
    font-size: 2.2rem;
    font-weight: 300;

    @media only screen and (max-width: 900px) {
      font-size: 1.7rem;
    }

    @media only screen and (max-width: 700px) {
      font-size: 2.2rem;
    }
  }

  > div {
    position: sticky;
    font-size: 3rem;
    font-weight: 100;
    letter-spacing: -0.2rem;
    top: 50vh;
    cursor: pointer;
    z-index: 901;
  }
`
