import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import data from '../provider/image'
import SEO from '../components/seo'
import { OpenEffect } from '../components/openEffect'

export const TopPage = (props) => {
  return (
    <>
      {props.location.state && props.location.state.inside ? <OpenEffect /> : null}
      <SEO title="" />
      <Wrapper>
        <ExtendedImage fluid={data().bgImage.childImageSharp.fluid} />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`
const ExtendedImage = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  border: 6px solid white;
`
