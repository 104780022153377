import React from 'react'
import styled from 'styled-components'

export const BlackFrame = () => {
  return (
    <>
      <Top />
      <Left />
      <Right />
      <Bottom />
    </>
  )
}

const common = styled.div`
  position: fixed;
  background-color: #222;
  z-index: 999;
`

const Top = styled(common)`
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
`
const Left = styled(common)`
  top: 0;
  left: 0;
  width: 4px;
  height: 100vh;
`
const Right = styled(common)`
  top: 0;
  right: 0;
  width: 4px;
  height: 100vh;
`
const Bottom = styled(common)`
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 4px;
`
