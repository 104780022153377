import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export const ScrollFadeIn = (props) => {
  const compoRef = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    let observer = null
    if (typeof window !== 'undefined' && !!window.IntersectionObserver) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setVisible(entry.isIntersecting)
          })
        },
        {
          rootMargin: '100px 0px -100px 0px',
        }
      )

      observer.observe(compoRef.current)
    }
    // eslint-disable-next-line
    return () => observer.unobserve(compoRef.current)
  }, [])

  return (
    <FadeIn
      ref={compoRef}
      style={{ opacity: visible ? 1 : 0, visibility: visible ? 'visible' : 'hidden' }}
    >
      {props.children}
    </FadeIn>
  )
}

const FadeIn = styled.div`
  transition: opacity 0.8s ease-out, visibility 0.8s ease-out;
`
